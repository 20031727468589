import { ModalType } from 'pmt-modules/dialog'

import DeleteCartDialog from '../components/OrderPage/DeleteCartDialog'

import ProductDialog from '../pages/product/dialog'
import DietaryInfoDialog from '../pages/catalog/components/DietaryInfoDialog'
import SuggestionDialog from '../pages/catalog/components/SuggestionDialog'
import UpsellingDialog from '../pages/catalog/components/UpsellingDialog'

import CartDiffItemsDialog from '../components/Cart/CartDiffItemsDialog'
import CartModifierDialog from '../components/CartModifier/CartModifierDialog'
import UnavailableDueDateDialog from '../components/Slots/UnavailableDueDateDialog'
import OrderModeDisabledDialog from '../pages/catalog/components/OrderModeDisabledDialog'
import RestaurantDisabledDialog from '../pages/catalog/components/RestaurantDisabledDialog'
import TableNotFoundDialog from '../pages/catalog/components/TableNotFoundDialog'
import ClosedStoreDialog from '../pages/catalog/components/ClosedStoreDialog'
import ExpiredSessionDialog from '../pages/payment/components/ExpiredSessionDialog'

const dialogs = [
  {
    type: ModalType.DELETE_CART,
    view: DeleteCartDialog,
  },
  {
    type: ModalType.ORDER_PLUGIN_PRODUCT_VIEW,
    view: ProductDialog,
  },
  {
    type: ModalType.SUGGESTION_VIEW,
    view: SuggestionDialog,
  },
  {
    type: ModalType.UPSELLING_VIEW,
    view: UpsellingDialog,
  },
  {
    type: ModalType.CART_MODIFIER_VIEW,
    view: CartModifierDialog,
  },
  {
    type: ModalType.CART_DIFF_ITEMS,
    view: CartDiffItemsDialog,
  },
  {
    type: ModalType.DIETARY_INFO,
    view: DietaryInfoDialog,
  },
  {
    type: ModalType.UNAVAILABLE_DUE_DATE,
    view: UnavailableDueDateDialog,
  },
  {
    type: ModalType.ORDER_MODE_DISABLED,
    view: OrderModeDisabledDialog,
  },
  {
    type: ModalType.RESTAURANT_DISABLED,
    view: RestaurantDisabledDialog,
  },
  {
    type: ModalType.RESTAURANT_CLOSED,
    view: ClosedStoreDialog,
  },
  {
    type: ModalType.TABLE_NOT_FOUND,
    view: TableNotFoundDialog,
  },
  {
    type: ModalType.EXPIRED_SESSION,
    view: ExpiredSessionDialog,
  },
]

export default dialogs
