import { tr } from 'pmt-modules/i18n'
import React from 'react'
import { connect } from 'react-redux'

import { hideExpiredSessionDialog, resetOrderPlugin } from 'pmt-modules/orderPlugin'
import RoutingContainer from 'pmt-modules/routing/components/RoutingContainer'

import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { makeStyles } from '@material-ui/core/styles'
import { resetOrderFrontFull } from 'pmt-modules/orderFront'
import Button from 'app/components/Button'

const useStyles = makeStyles(theme => ({
  root: {
    minWidth: 300,
    minHeight: 200,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
}))

const ExpiredSessionDialog = ({
  hideExpiredSessionDialog,
  resetOrderFrontFull,
  resetOrderPlugin,
  restaurantId,
}) => {
  const classes = useStyles()

  return (
    <RoutingContainer>
      {({ redirectTo, getRoute }) => (
        <div className={classes.root}>
          <DialogTitle>{tr('order.payment.session_expired.dialog.title')}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {tr(`order.payment.session_expired.dialog.content`)}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              label={tr('order.payment.session_expired.dialog.close')}
              onClick={() => {
                hideExpiredSessionDialog()
                resetOrderFrontFull()

                resetOrderPlugin()
                if (restaurantId) {
                  redirectTo(getRoute('ORDER__CATALOG'), {
                    restaurantId: restaurantId,
                  })
                } else {
                  // we can't redirect to redirectUri as redirectUri often contains placeholders like :restaurantId
                  // but we don't have this data anymore
                  redirectTo(getRoute('ORDER__STORE_LOCATOR'))
                }
              }}
            />
          </DialogActions>
        </div>
      )}
    </RoutingContainer>
  )
}

// Dialog root properties
ExpiredSessionDialog.DialogRootProps = {
  disableBackdropClick: true,
  disableEscapeKeyDown: true,
}

const mapStateToProps = () => ({})

export default connect(mapStateToProps, {
  hideExpiredSessionDialog,
  resetOrderFrontFull,
  resetOrderPlugin,
})(ExpiredSessionDialog)
