import React from 'react'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import isNil from 'lodash/isNil'
import isEmpty from 'lodash/isEmpty'
import { haveAuthCredentials, getAuth } from 'pmt-modules/auth'
import { withCatalog } from 'pmt-modules/catalog'
import { EventManager, SendEventContainer } from 'pmt-modules/event'
import { withAppConfig } from 'pmt-modules/appConfig'
import withRestaurant from 'pmt-modules/restaurant/components/withRestaurant'
import { withUserMe } from 'pmt-modules/userMe'
import {
  CreditCardsContainer,
  PspRegisterUserCardType,
  getIsFetchingPostCreditCardRegistrationType,
  getSendUserCreditCardToPspIsFetching,
  getPostPspDatasIsFetching,
  resetUserCreditCardToPspSend,
} from 'pmt-modules/creditCard'
import { getItemListFromCart } from 'pmt-modules/cart'
import {
  withOrderProperties,
  OrderPluginUrlCheckerContainer,
  getOrderData,
  getAppConfigFrontSettings,
  showExpiredSessionDialog,
} from 'pmt-modules/orderPlugin'
import { isFetchingOrderPost } from 'pmt-modules/orderPost'
import { isFetchingOrderPreview, resetOrderPreviewError } from 'pmt-modules/orderPreview'
import { getOrderPreviewVerifications } from 'pmt-modules/orderPreview'
import { getAuthenticatedUser } from 'pmt-modules/authenticatedUser/selectors'
import UserType from 'pmt-modules/user/constants/UserType'
import { withRestaurantsGroupMine } from 'pmt-modules/restaurantsGroup/components'
import withScrollToTop from 'pmt-ui/Layout/withScrollToTop'
import withWidth, { isWidthUp } from 'pmt-ui/utils/withWidth'
import OrderPage from '../../components/OrderPage'
import View from './View'

/**
 * @specs N/A
 */
@withAppConfig
@withUserMe
@withOrderProperties
@withRestaurant
@withRestaurantsGroupMine()
@withCatalog
@withScrollToTop()
class PaymentPage extends React.PureComponent {
  state = {
    expiredSessionDialogShown: false,
  }

  constructor(props) {
    super(props)
    // TODO: only if there is data to avoid unecessary page re-render
    props.resetUserCreditCardToPspSend()

    // reset order preview error message, we want to display it only when the user click on finalize
    // order and the preview fail, not if a previous preview failed.
    props.resetOrderPreviewError()
  }

  // we need to have the restaurant to check order data
  // if we don't, it will return null every time
  componentDidUpdate(prevProps) {
    if (this.state.expiredSessionDialogShown) {
      return // Stop re-triggering the dialog
    }

    // SECURITY
    // user is not connected or
    // if the order data is null, it means that one mandatory information
    // to access the payment page is missing
    const noOrderData = isNil(this.props.orderData)
    const hasOrderPropertiesRestaurant = !isNil(this.props.orderProperties.restaurant)
    const noCredentials = !this.props.haveAuthCredentials
    const noRestaurantId = isNil(this.props.orderProperties.restaurantId)
    const noRestaurant =
      prevProps.isFetchingRestaurant && !this.props.isFetchingRestaurant && !this.props.restaurant

    if (
      (noOrderData && hasOrderPropertiesRestaurant) ||
      noCredentials ||
      noRestaurantId ||
      noRestaurant ||
      // cart is empty, mostly because items has been removed because they are locked
      // Note: most of the time, deleteCartItemMiddleware will redirect before we arrive here
      //  But in some cases we could be here and we want to redirect. Better to be safe.
      (isEmpty(this.props.orderData.products) && isEmpty(this.props.orderData.menus))
    ) {
      // this can be due to the user staying more than 1 hour on the payment page, then refreshing the page
      // see redux-persist expiration delay ("persistExpiresAt")
      this.setState({ expiredSessionDialogShown: true }) // Mark that we've shown the dialog

      // can be undefined
      const restaurantId = this.props.orderProperties.restaurantId
      // we can't redirect to redirectUri as redirectUri often contains placeholders like :restaurantId
      // but we don't have this data anymore
      this.props.showExpiredSessionDialog(restaurantId)
    }
  }

  render() {
    const {
      isFetchingPostCreditCardRegistrationType,
      isFetchingSendUserCreditCardToPsp,
      isFetchingPostPspDatas,
      isFetchingOrderPost,
      isFetchingOrderPreview,
      restaurant,
      restaurantsGroup,
      authenticatedUser,
      cartItemList,
      location,
      orderPreviewVerifications,
      orderProperties,
      width,
      me,
      frontSettings,
    } = this.props

    const canRegisterCardWithName = authenticatedUser && authenticatedUser.type === UserType.NORMAL

    const isFetchingRestaurant = !restaurant
    const isFetchingUser = !authenticatedUser || (authenticatedUser.type === UserType.NORMAL && !me)
    const isFetchingCard =
      isFetchingPostCreditCardRegistrationType &&
      restaurant.pspRegisterUserCardType === PspRegisterUserCardType.WEB_SERVICES

    const isFetching =
      isFetchingRestaurant ||
      isFetchingUser ||
      isFetchingSendUserCreditCardToPsp ||
      isFetchingPostPspDatas ||
      isFetchingOrderPost ||
      isFetchingOrderPreview ||
      isFetchingCard

    return (
      <OrderPage
        headerProps={{
          displayTitle: isWidthUp('md', width),
        }}
        orderProperties={orderProperties}
        restaurant={restaurant}
        location={location}
      >
        <OrderPluginUrlCheckerContainer location={location} verifyMode>
          <CreditCardsContainer
            // we don't load usercards if not necessary (eg. when using an external web payment page, and not registered usercards)
            // and doing so prevents a bug where the Mangopay existing cards are automatically loaded and the first one selected,
            // so the order is sent with a cardId, which is not what we want now because we want to use the Web Payin feature (for PCI-DSS compliance)
            userId={
              authenticatedUser &&
              authenticatedUser.id &&
              restaurant &&
              restaurant.pspRegisterUserCardType === PspRegisterUserCardType.WEB_SERVICES
            }
            storeId={restaurant && restaurant.id}
          >
            {({
              isFetchingCreditCards,
              creditCards,
              selectedCreditCard,
              onToggleCard,
              onClickDeleteCreditCard,
              onResetUserCard,
            }) => (
              <SendEventContainer
                send={
                  orderProperties &&
                  !isNil(orderProperties.restaurant) &&
                  !isNil(authenticatedUser && authenticatedUser.email)
                }
                event={EventManager.Events.ON_PAGE_LOADED}
                eventProps={() => ({
                  items: cartItemList,
                  restaurant: orderProperties.restaurant,
                  dueDate: orderProperties.dueDate,
                  user: authenticatedUser && authenticatedUser.email,
                })}
              >
                <View
                  isFetching={isFetching}
                  isFetchingCreditCards={isFetchingCreditCards}
                  user={authenticatedUser}
                  orderPreviewVerifications={orderPreviewVerifications}
                  orderProperties={orderProperties}
                  restaurant={restaurant}
                  restaurantsGroup={restaurantsGroup}
                  creditCards={creditCards}
                  selectedCreditCard={selectedCreditCard}
                  onToggleCreditCard={onToggleCard}
                  onClickDeleteCreditCard={onClickDeleteCreditCard}
                  onResetUserCard={onResetUserCard}
                  canRegisterCardWithName={canRegisterCardWithName}
                  frontSettings={frontSettings}
                />
              </SendEventContainer>
            )}
          </CreditCardsContainer>
        </OrderPluginUrlCheckerContainer>
      </OrderPage>
    )
  }
}

const mapStateToProps = (state, props) => ({
  haveAuthCredentials: haveAuthCredentials(state),
  auth: getAuth(),
  authenticatedUser: getAuthenticatedUser(state),

  isFetchingPostCreditCardRegistrationType: getIsFetchingPostCreditCardRegistrationType(state),
  isFetchingSendUserCreditCardToPsp: getSendUserCreditCardToPspIsFetching(state),
  isFetchingPostPspDatas: getPostPspDatasIsFetching(state),
  isFetchingOrderPost: isFetchingOrderPost(state),
  isFetchingOrderPreview: isFetchingOrderPreview(state),

  orderData: getOrderData(state),
  orderPreviewVerifications: getOrderPreviewVerifications(state),
  frontSettings: getAppConfigFrontSettings(state),
  cartItemList: getItemListFromCart(state),
})

export default compose(
  connect(mapStateToProps, {
    resetUserCreditCardToPspSend,
    resetOrderPreviewError,
    showExpiredSessionDialog,
  }),
  withWidth()
)(PaymentPage)
